import { twMerge } from "tailwind-merge";
import { Container } from "components/Container";
import { ParallaxBgHeadline } from "components/ParallaxBgHeadline";
import { TitleJost, DividerUnderline } from "@brightstarstudios/components";
import React, { ReactNode } from "react";
import { Parallax } from "react-scroll-parallax";

interface BlockTextCenterProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "title"> {
  /** The title of the block. */
  title: string | ReactNode;
  /** The text that will be parallaxed. */
  parallaxText?: string;
}

/**
 * This is a block that shows a centered title, some parallaxed text and some content.
 *
 * @example
 * <BlockTextCenter title="Title" parallaxText="Parallaxed text">
 *    <p>Content</p>
 * </BlockTextCenter>
 */
export const BlockTextCenter: React.FC<BlockTextCenterProps> = ({
  className = "",
  title,
  parallaxText,
  children,
  ...rest
}) => {
  return (
    <>
      {parallaxText && (
        <Parallax speed={-10}>
          <ParallaxBgHeadline className="left-8 lg:left-auto lg:right-8 lg:text-right">
            {parallaxText}
          </ParallaxBgHeadline>
        </Parallax>
      )}
      <Container
        className={twMerge("relative text-white", className)}
        {...rest}
      >
        <div className="mx-auto max-w-2xl">
          {typeof title === "string" ? (
            <TitleJost
              size="extra-large"
              variant="h1"
              className="mb-8 text-center font-extrabold uppercase"
            >
              {title}
            </TitleJost>
          ) : (
            title
          )}
          <DividerUnderline />
          <div className="text-center">{children}</div>
        </div>
      </Container>
    </>
  );
};
