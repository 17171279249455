import { twMerge } from "tailwind-merge";
import React, { HTMLAttributes } from "react";
import Link from "next/link";
import Image from "next/image";
import { TitleJost } from "@brightstarstudios/components";
import { BorderBox } from "./BorderBox";
import { StrapiBlogPost } from "api/strapi-blog";

// This is a hardcoded map of gif urls to video urls. The gif url is the key and the video url is the value. This lets us replace gifs that are too large with videos that are smaller.
const GIF_REPLACE_VIDEO_MAP = new Map([
  [
    "/cms/images/1_b_Fu_Jsj_Sge4o_V_Zxb0x_Uh7cw_adf4a1f248.gif",
    "videos/gif-replacements/thanabus-cup-flames.mp4",
  ],
]);

interface NewsCardProps extends HTMLAttributes<HTMLDivElement> {
  post: StrapiBlogPost;
}

export const NewsCard: React.FC<NewsCardProps> = ({
  className = "",
  post,
  ...rest
}) => {
  const { slug, title, thumbnail } = post;

  return (
    <div
      className={twMerge(
        "w-full overflow-hidden rounded-lg shadow-md transition-all duration-700",
        className,
      )}
      {...rest}
    >
      <BorderBox className="text-white">
        <Link
          href={{
            pathname: "/news/[slug]",
            query: { slug: slug },
          }}
          className="w-full"
        >
          <div
            className={
              "pointer-events-none flex min-h-[350px] flex-col items-start justify-between rounded-lg p-10"
            }
          >
            <TitleJost
              size="medium"
              variant="h2"
              className="text-lg font-bold uppercase text-primary-teal-500"
            >
              {title}
            </TitleJost>
          </div>
        </Link>
      </BorderBox>
      <span className="absolute left-0 top-0 -z-10 h-full w-full overflow-hidden rounded-lg">
        {GIF_REPLACE_VIDEO_MAP.has(thumbnail) ? (
          // If the thumbnail is in the map, we replace it with a video.
          <video
            src={GIF_REPLACE_VIDEO_MAP.get(thumbnail)}
            autoPlay
            loop
            muted
            playsInline
            className="h-full w-full object-cover object-bottom"
          />
        ) : (
          // Otherwise, we just use the thumbnail as an image.
          <Image
            src={thumbnail}
            alt={title}
            fill
            // This image takes up 1/2th of the viewport width on desktop and all of it on mobile.
            sizes="(min-width: 1024px) 50w, 100vw"
            className="h-full w-full object-cover object-bottom"
          />
        )}
        <div className="absolute left-0 top-0 z-10 h-full w-full bg-black opacity-50" />
      </span>
    </div>
  );
};
