import React from "react";
import Head from "next/head";
import { IS_DEV_ENVIRONMENT } from "utils/isDevEnvironment";

interface HeaderProps {
  title: string;
  description?: string;
}

const defaultDescription =
  "Ember Sword is a Free-to-Play Massively Multiplayer Online Role-playing Game (MMORPG) taking place in a player-driven dystopian fantasy universe with a classless and fast-paced combat system.";

export const Header: React.FC<HeaderProps> = ({
  title = "",
  description = defaultDescription,
}) => {
  const preTitle = IS_DEV_ENVIRONMENT ? "DEV - " : "";
  const baseTitle = "Ember Sword";
  const completeTitle = title
    ? `${preTitle}${title} - ${baseTitle}`
    : baseTitle;

  return (
    <Head>
      <title>{completeTitle}</title>
      <meta property="og:title" content={completeTitle} key="title" />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta name="twitter:card" content={description} />
      <meta
        property="og:image"
        content="https://embersword.com/images/opengraph-image.jpg"
      />
      <meta
        property="og:image:secure_url"
        content="https://embersword.com/images/opengraph-image.jpg"
      />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:alt" content="Ember Sword landscape" />
      <meta property="og:image:width" content="1024" />
      <meta property="og:image:height" content="619" />
    </Head>
  );
};
