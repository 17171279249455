import { twMerge } from "tailwind-merge";
import React from "react";

export const ParallaxBgHeadline: React.FC<
  React.HTMLAttributes<HTMLHeadingElement>
> = ({ children, className = "", ...rest }) => {
  return (
    <h1
      className={twMerge(
        "pointer-events-none absolute top-0 font-jost text-6xl font-extrabold uppercase opacity-10 md:text-8xl lg:text-[10rem] xl:text-[12rem]",
        className,
      )}
      {...rest}
    >
      {children}
    </h1>
  );
};
