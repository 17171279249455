import { twMerge } from "tailwind-merge";
import React, { ReactElement } from "react";
import { useState } from "react";
import { Swiper, SwiperProps, SwiperSlideProps } from "swiper/react";
import "swiper/css";
import SwiperClass from "swiper";

interface SliderSlantedProps extends SwiperProps {
  className?: string;
  onSlideChange?: (swiper: SwiperClass) => void;
  onSwiper?: (swiper: SwiperClass) => void;
  children?: React.ReactNode;
}

export const SwiperSlanted: React.FC<SliderSlantedProps> = ({
  className = "",
  onSlideChange = () => {},
  onSwiper = () => {},
  children,
  ...rest
}) => {
  const [activeSlide, setActiveSlide] = useState<number>(0);

  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={50}
      onSlideChange={(swiper) => {
        setActiveSlide(swiper.activeIndex);
        onSlideChange(swiper);
      }}
      breakpoints={{
        1024: {
          slidesPerView: 1.25,
          spaceBetween: 40,
        },
        1300: {
          slidesPerView: 1.5,
        },
      }}
      onSwiper={(swiper) => {
        setActiveSlide(swiper.activeIndex);
        onSwiper(swiper);
      }}
      className={twMerge("!overflow-visible", className)}
      {...rest}
    >
      {React.Children.map(children, (child, i) => {
        const activeSlideClass =
          activeSlide === i ? "lg:-translate-y-10 lg:opacity-100" : "";
        const notActiveSlide =
          activeSlide > i ? "lg:opacity-0 pointer-events-none" : "";

        if (React.isValidElement(child)) {
          return React.cloneElement(child as ReactElement<SwiperSlideProps>, {
            className: `w-full drop-shadow-2xl transition-all select-none duration-500 ${activeSlideClass} ${notActiveSlide} ${child.props.className}`,
          });
        }
        return child;
      })}
    </Swiper>
  );
};
