import { twMerge } from "tailwind-merge";
import React from "react";

export const Section: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
  className = "",
  ...rest
}) => {
  return (
    <section
      className={twMerge("flex flex-1 flex-col py-32", className)}
      {...rest}
    >
      {children}
    </section>
  );
};
