import { TitleJost } from "@brightstarstudios/components";
import React, { HTMLAttributes, useState } from "react";
import { Parallax } from "react-scroll-parallax";
import { SwiperSlide } from "swiper/react";
import { NewsCard } from "components/NewsCard";
import { ParallaxBgHeadline } from "components/ParallaxBgHeadline";
import { Container } from "components/Container";
import { SwiperSlanted } from "components/SwiperSlanted";
import { Pagination } from "swiper/modules";
import { StrapiBlogPost } from "api/strapi-blog";
import "swiper/css";

interface BlockLatestNewsProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  blogs: StrapiBlogPost[];
}

export const BlockLatestNews: React.FC<BlockLatestNewsProps> = ({ blogs }) => {
  const [activeSlide, setActiveSlide] = useState<number>(1);

  const pagination = {
    el: ".pagination",
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return (
        '<div class="rotate-45 bullet-blue ' +
        className +
        '"><span class="p-1 w-1 h-1 block cursor-pointer"/></div>'
      );
    },
  };
  return (
    <>
      <Parallax speed={-10}>
        <ParallaxBgHeadline className="left-8">News</ParallaxBgHeadline>
      </Parallax>
      <Container className="my-12">
        <div className="gap-4 lg:grid lg:grid-cols-12 lg:gap-6">
          <div className="mb-16 flex flex-col items-center gap-8 text-center lg:col-span-3 lg:col-start-1 lg:mb-0 lg:items-start lg:justify-between lg:text-left">
            <TitleJost
              size="large"
              variant="h1"
              className="font-extrabold uppercase xl:text-6xl"
            >
              Latest news
            </TitleJost>

            <div className="pagination relative z-40 mt-4 flex flex-row flex-nowrap gap-4 lg:-mb-6" />
          </div>
          <div className="lg:col-span-8">
            <SwiperSlanted
              modules={[Pagination]}
              pagination={pagination}
              onSlideChange={(swiper) => {
                setActiveSlide(swiper.activeIndex);
              }}
              onSwiper={(swiper) => {
                setActiveSlide(swiper.activeIndex);
              }}
              className="cursor-pointer overflow-visible"
            >
              {blogs.map((blog, i: number) => {
                const activeSlideClass =
                  activeSlide === i
                    ? "border-4 border-primary-teal-500 rounded-xl"
                    : "";

                return (
                  <SwiperSlide key={blog.title} className={activeSlideClass}>
                    <NewsCard post={blog} />
                  </SwiperSlide>
                );
              })}
            </SwiperSlanted>
          </div>
        </div>
      </Container>
    </>
  );
};
